import React from "react";
import CookieConsent, { getCookieConsentValue, Cookies } from "react-cookie-consent";
import { render } from "storyblok-rich-text-react-renderer";
import { initGA } from "./GoogleAnalytics";

export const CookieBanner = ({ title, description, acceptText, declineText }) => {
    const [hideBanner, setHideBanner] = React.useState(false);
    const renderedDescription = typeof description === "string" ? description : render(description);
    const isConsent = getCookieConsentValue();

    const handleAcceptCookie = () => {
        // if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
        //   initGA(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
        //   setHideBanner(true);
        // }
        initGA();
        setHideBanner(true);
    };

    const handleDeclineCookie = () => {
        //remove google analytics cookies
        Cookies.remove("_ga");
        Cookies.remove("_gat");
        Cookies.remove("_gid");
        setHideBanner(true);
    };

    React.useEffect(() => {
        if (isConsent === "true") {
            handleAcceptCookie();
        }
    }, []);

    return (
        <div
            className={
                "cookie-banner duration-300 ease-in-out-ios fixed bottom-0 right-0 left-0 bg-liminal-white z-50 text-liminal-blue transform " +
                (hideBanner ? "translate-y-full" : "")
            }>
            <CookieConsent
                enableDeclineButton
                onAccept={handleAcceptCookie}
                onDecline={handleDeclineCookie}
                hideOnAccept={false}
                buttonText={acceptText}
                declineButtonText={declineText}
                ariaAcceptLabel={acceptText}
                ariaDeclineLabel={declineText}
                containerClasses={"container py-4 grid grid-cols-6 phablet:grid-cols-12   gap-x-2 laptop:gap-x-4 large:gap-x-8 items-center "}
                contentClasses={"col-span-6 phablet:col-span-5 laptop:col-span-4 laptop:col-start-2 rich-text-block"}
                buttonWrapperClasses={
                    "col-span-6 laptop:col-span-4   phablet:col-start-7 laptop:col-start-8   grid grid-cols-6 laptop:grid-cols-4   gap-x-2 laptop:gap-x-4 large:gap-x-8  auto-rows-min	items-center "
                }
                buttonClasses={
                    "hover-aria after:text-liminal-blue before:text-liminal-blue col-span-3  phablet:col-span-3 laptop:col-span-2 bg-liminal-yellow text-liminal-yellow font-bold	 py-2 px-10"
                }
                declineButtonClasses={
                    "hover-aria after:text-liminal-white before:text-liminal-white col-span-3 phablet:col-span-3  laptop:col-span-2 bg-liminal-blue text-liminal-blue py-2 px-10"
                }
                disableStyles>
                <h4 className={"font-bold mb-0 pb-0"}> {title} </h4>
                <div className={"text-sm rich-text-block"}> {renderedDescription} </div>
            </CookieConsent>
        </div>
    );
};
