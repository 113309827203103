import * as ReactGA from 'react-ga';
// import ReactGA from 'react-ga4';

export const initGA = () => {
  // if (process.env.NODE_ENV === 'production') {

  // ReactGA.initialize('UA-209716683-1');

  ReactGA.initialize('UA-209716683-1', {
    gaOptions: { anonymizeIp: true, allowAdFeatures: true },
  });
  ReactGA.pageview(window.location.pathname + window.location.search);

  // Send pageview with a custom path
  // ReactGA.send({ hitType: 'pageview', page: '/my-path' });

  // // Send a custom event
  // ReactGA.event({
  //   category: 'your category',
  //   action: 'your action',
  //   label: 'your label', // optional
  //   value: 99, // optional, must be a number
  //   nonInteraction: true, // optional, true/false
  //   transport: 'xhr', // optional, beacon/xhr/image
  // });
  // }
};
