import gsap from "gsap";
import React, { useEffect } from "react";
import { render } from "storyblok-rich-text-react-renderer";
import { useLayout } from "../../hooks/useLayout";
import { useWindowDimensions } from "../../hooks/useWindowDimensions";
import SplitText from "../../utils/SplitTextPlugin.js";
import { useInView } from "react-intersection-observer";

export const SlideInText = ({ text, visible, delayClass, fast, hideInstant }) => {
    if (fast) {
        return (
            <div className={"slide-text-container "}>
                <div
                    className={
                        "slide-text ease-in-out-ios " +
                        delayClass +
                        " " +
                        (visible ? "slide-text-hidden " + (hideInstant ? " transition-none " : " transition-all") : "transition-all")
                    }>
                    {text}
                </div>
            </div>
        );
    } else {
        return (
            <div className={"slide-text-container "}>
                <div
                    className={
                        "slide-text ease-in-out-ios " +
                        delayClass +
                        " " +
                        (visible
                            ? "slide-text-hidden " + (hideInstant ? " transition-none " : " transition-all duration-900")
                            : "transition-all duration-700")
                    }>
                    {text}
                </div>
            </div>
        );
    }
};

export const AnimateInRichText = ({ uniqueID, text, instant }) => {
    const refText = React.useRef(null);
    const renderedText = typeof text === "string" ? text : render(text);

    const [tl] = React.useState(gsap.timeline({ paused: true }));
    const { ref, inView } = useInView({ triggerOnce: true, rootMargin: "0px 0px -10% 0px" });

    React.useEffect(() => {
        tl.from(refText.current.children, {
            yPercent: 100,
            autoAlpha: 0,
            duration: 0.7,
            ease: "Power3.easeOut",
            stagger: 0.1,
            delay: 0.2,
        });

        if (instant) tl.play();
    }, [tl]);

    useEffect(() => {
        if (inView) {
            tl.play();
        }
    }, [inView, tl]);

    return (
        <div ref={ref}>
            <div ref={refText}>{renderedText}</div>
        </div>
    );
};

export const AnimateInSplitLines = ({ text, delay, start, useScroll }) => {
    const refText = React.useRef(null);

    const { isPageLoaded } = useLayout();
    const [tl] = React.useState(gsap.timeline({ paused: true }));

    React.useEffect(() => {
        let splitTarget = refText.current;
        if (refText.current.children.length !== 0) splitTarget = refText.current.children;

        if (isPageLoaded === true) {
            const titleLines = new SplitText(splitTarget, { type: "lines", linesClass: "lineChild" });
            const titleLinesParents = new SplitText(splitTarget, { type: "lines", linesClass: "lineParent" });

            tl.from(titleLines.lines, {
                transformOrigin: "left center",
                autoAlpha: 0,
                y: "100%",
                duration: 0.7,
                stagger: 0.1,
                ease: "Power3.easeOut",
                onComplete: () => {
                    titleLinesParents.revert();
                    titleLines.revert();
                },
            });
        }

        // DELAY ENSURES NOT LOADED FONT FACE DOESNT MESS UP THE LINE ANIMATION! (NEED BETTER APPROACH)
    }, [isPageLoaded]);

    React.useEffect(() => {
        if (tl && tl.paused()) {
            if (start) {
                setTimeout(function () {
                    tl.play();
                }, delay);
            }
        }
    }, [start]);

    return useScroll ? <div ref={refText}>{text}</div> : <div ref={refText}>{text}</div>;
};

AnimateInSplitLines.defaultProps = {
    start: false,
    useScroll: true,
    delay: 0,
};

export const AnimateInWrapper = ({ element, delay, start }) => {
    const refWrapper = React.useRef(null);
    const [tl] = React.useState(gsap.timeline({ paused: true }));

    React.useEffect(() => {
        tl.from(element, {
            yPercent: 100,
            autoAlpha: 0,
            duration: 0.7,
            ease: "Power3.easeOut",
            stagger: 0.1,
        });
    }, []);

    React.useEffect(() => {
        if (tl && tl.paused()) {
            if (start) {
                setTimeout(function () {
                    tl.play();
                }, delay);
            }
        }
    }, [start]);

    return (
        <div ref={refWrapper} className={"overflow-hidden"}>
            {element}
        </div>
    );
};

export const FloatingText = ({ text, position, palette }) => {
    const refText = React.useRef(null);
    const ref = React.useRef(null);

    const { windowWidth } = useWindowDimensions();

    React.useEffect(() => {
        const delayedTimer = setTimeout(() => {
            const tl = gsap.timeline();
            if (windowWidth > 480) {
                const moveXFrom = position === "left" ? "-300" : "300";
                const moveXTo = position === "left" ? "-20" : "-50";
                tl.fromTo(
                    refText.current,
                    { x: moveXFrom },
                    {
                        x: moveXTo,
                        duration: 2,
                        scrollTrigger: {
                            trigger: ref.current,
                            scrub: true,
                        },
                    }
                );
            } else {
                console.log(position);
                const moveXFrom = position === "left" ? "250" : "-200";
                const moveXTo = position === "left" ? "-50" : "0";
                tl.fromTo(
                    refText.current,
                    { xPercent: moveXFrom },
                    {
                        xPercent: moveXTo,
                        duration: 2,
                        scrollTrigger: {
                            trigger: refText.current,
                            scrub: true,
                        },
                    }
                );
            }
        }, 1000);

        return () => {
            clearTimeout(delayedTimer);
        };
    }, []);

    return (
        <div ref={ref}>
            <div
                ref={refText}
                className={
                    "absolute text-9xl text-" +
                    palette.text_secondary +
                    " uppercase font-bw-gradual " +
                    (position == "left" ? "bottom-0 left-0" : "bottom-0 right-0")
                }>
                {text}
            </div>
        </div>
    );
};
