import gsap from "gsap";
import React from "react";

// const Nav = ({ settings, lang }) => {
const LoadingScreen = ({ loaded }) => {
    const [tl_loop] = React.useState(gsap.timeline({ paused: true }));
    const [tl_ready] = React.useState(gsap.timeline({ paused: true }));

    const loaderRef = React.useRef(null);
    const liRef = React.useRef(null);
    const miRef = React.useRef(null);
    const nalRef = React.useRef(null);
    const refBg = React.useRef(null);
    const refLogo = React.useRef(null);
    const [loopAnimation, setLoopAnimation] = React.useState();

    React.useEffect(() => {
        const tlPreloader = gsap.timeline({ delay: 0.4 });
        tlPreloader.to(refLogo.current, {
            opacity: 0,
            duration: 1,
            stagger: 0.1,
            ease: "power3.inOut",
        });
        tlPreloader.to(
            refBg.current.children,
            {
                yPercent: -101,
                duration: 1,
                stagger: 0.1,
                ease: "power3.inOut",
            },
            "<0.1"
        );
        tlPreloader.to(loaderRef.current, { autoAlpha: 0 });

        // tl_loop.set(miRef.current, {
        //     x: 0,
        //     y: 0,
        // });
        // tl_loop.set(nalRef.current, {
        //     x: 0,
        //     y: 0,
        // });
        // tl_loop.set(liRef.current, {
        //     x: 0,
        //     y: 0,
        // });

        // tl_loop.add('startLoading');
        // tl_loop.to(liRef.current, {
        //   x: 0,
        //   y: -80,
        //   duration: 0.35,
        //   ease: 'power3.inOut',
        //   delay: 1,
        // });
        // tl_loop.to(liRef.current, {
        //   x: 0,
        //   y: 0,
        //   duration: 0.35,
        //   ease: 'power3.inOut',
        // });
        // tl_loop.to(miRef.current, {
        //   x: 0,
        //   y: 80,
        //   duration: 0.35,
        //   ease: 'power3.inOut',
        // });
        // tl_loop.to(nalRef.current, {
        //   x: 0,
        //   y: 160,
        //   duration: 0.35,
        //   ease: 'power3.inOut',
        // });
        // tl_loop.to(nalRef.current, {
        //   x: -50,
        //   y: 160,
        //   duration: 0.35,
        //   ease: 'power3.inOut',
        // });
        // tl_loop.to(nalRef.current, {
        //   x: 0,
        //   y: 160,
        //   duration: 0.35,
        //   ease: 'power3.inOut',
        // });
        // tl_loop.to(nalRef.current, {
        //   x: 0,
        //   y: 0,
        //   duration: 0.35,
        //   ease: 'power3.inOut',
        // });
        // tl_loop.to(miRef.current, {
        //   x: 0,
        //   y: 0,
        //   duration: 0.35,
        //   ease: 'power3.inOut',
        // });
        // tl_loop.add('endLoading');

        // tl_ready.to(
        //     liRef.current,
        //     {
        //         x: 0,
        //         y: 0,
        //         duration: 0.2,
        //         ease: "power3.inOut",
        //     },
        //     0
        // );
        // tl_ready.to(
        //     miRef.current,
        //     {
        //         x: 0,
        //         y: 0,
        //         duration: 0.2,
        //         ease: "power3.inOut",
        //     },
        //     0
        // );
        // tl_ready.to(
        //     nalRef.current,
        //     {
        //         x: 0,
        //         y: 0,
        //         duration: 0.2,
        //         ease: "power3.inOut",
        //     },
        //     0
        // );

        // tl_ready.to(loaderRef.current, { autoAlpha: 0 });

        // setLoopAnimation(tl_loop.tweenFromTo("startLoading", "endLoading", { repeat: -1 }));
        // const introTimeout = setTimeout(function () {  tl_loop.play(); }, 1000);
        // return () => (clearTimeout(introTimeout))
        // tl_loop.play();
    }, []);

    React.useEffect(() => {
        if (loaded) {
            // loopAnimation.pause();
            tl_loop.pause();
            tl_ready.play();
        }
    });

    return (
        <div data-scroll className={"fixed inset-0 z-50 flex flex-row items-center justify-center"} ref={loaderRef}>
            <div className='fixed w-full h-full inset-0 flex justify-between' ref={refBg}>
                <div className='relative flex-1 h-full bg-liminal-blue'></div>
                <div className='relative flex-1 h-full bg-liminal-blue'></div>
                <div className='relative flex-1 h-full bg-liminal-blue'></div>
            </div>

            <div className={"transform scale-50 phablet:scale-75 tablet:scale-90 laptop:scale-100"} ref={refLogo}>
                <div className={"fill-current inline-block"} ref={liRef}>
                    <svg width='94' height='112' viewBox='0 0 94 112' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path d='M21.0188 10H10V104.972H66.9217V94.5906H21.0188V10Z' fill='white' />
                        <path
                            d='M81.8062 15.0225C80.2663 15.0225 78.7895 15.6702 77.7006 16.8232C76.6118 17.9763 76 19.5401 76 21.1707C76 22.8013 76.6118 24.3651 77.7006 25.5182C78.7895 26.6712 80.2663 27.319 81.8062 27.319C83.3461 27.319 84.8229 26.6712 85.9118 25.5182C87.0006 24.3651 87.6123 22.8013 87.6123 21.1707C87.6123 19.5401 87.0006 17.9763 85.9118 16.8232C84.8229 15.6702 83.3461 15.0225 81.8062 15.0225Z'
                            fill='white'
                        />
                        <path d='M87.3705 38.8458H77.5771V104.961H87.3705V38.8458Z' fill='white' />
                    </svg>
                </div>
                <div className={"fill-current inline-block"} ref={miRef}>
                    <svg width='124' height='112' viewBox='0 0 124 112' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path
                            d='M73.3174 37.3664C63.8896 37.3664 57.3966 42.5573 53.118 48.7499H52.8707C50.3015 41.362 45.1522 37.3664 36.2189 37.3664C32.6751 37.2791 29.166 38.121 26.0038 39.8171C22.8416 41.5132 20.1245 44.0109 18.0943 47.0879H17.8578V39.1878H8.06445V105.303H17.8578V64.0721C17.8578 53.8269 23.9746 46.4391 33.7679 46.4391C43.5612 46.4391 45.1522 53.1781 45.1522 61.7385V105.303H54.9455V64.0721C54.9455 53.8269 61.073 46.4391 70.8663 46.4391C80.6597 46.4391 82.2507 53.1781 82.2507 61.7385V105.303H92.044V59.143C92.044 45.2779 85.9272 37.3664 73.3174 37.3664Z'
                            fill='white'
                        />
                        <path
                            d='M111.054 15.3599C110.272 15.3244 109.49 15.457 108.757 15.7497C108.024 16.0425 107.355 16.4892 106.789 17.0631C106.224 17.637 105.774 18.3261 105.466 19.0891C105.159 19.852 105 20.6729 105 21.5025C105 22.332 105.159 23.153 105.466 23.9159C105.774 24.6789 106.224 25.368 106.789 25.9419C107.355 26.5158 108.024 26.9626 108.757 27.2553C109.49 27.548 110.272 27.6806 111.054 27.6451C111.837 27.6806 112.618 27.548 113.351 27.2553C114.084 26.9626 114.754 26.5158 115.319 25.9419C115.885 25.368 116.335 24.6789 116.643 23.9159C116.95 23.153 117.109 22.332 117.109 21.5025C117.109 20.6729 116.95 19.852 116.643 19.0891C116.335 18.3261 115.885 17.637 115.319 17.0631C114.754 16.4892 114.084 16.0425 113.351 15.7497C112.618 15.457 111.837 15.3244 111.054 15.3599Z'
                            fill='white'
                        />
                        <path d='M116.176 39.1877H106.383V105.303H116.176V39.1877Z' fill='white' />
                    </svg>
                </div>
                <div className={"fill-current inline-block"} ref={nalRef}>
                    <svg width='164' height='112' viewBox='0 0 164 112' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path
                            d='M40.964 37.3661C29.3164 37.3661 22.088 42.4204 18.4797 47.0876H18.2094V39.1875H7.50195V105.303H18.2094V64.0718C18.2094 53.8266 26.2369 46.4388 38.2842 46.4388C48.9916 46.4388 53.1406 53.1778 53.1406 61.7382V105.303H63.8597V59.1427C63.8597 43.9799 53.4109 37.3661 40.964 37.3661Z'
                            fill='white'
                        />
                        <path
                            d='M130.654 90.3904V58.2434C130.654 42.3066 119.007 37.3661 105.972 37.3661C86.1678 37.3661 77.9992 46.9624 77.3293 59.6664H88.0366C88.7065 50.0701 92.9965 46.4388 105.972 46.4388C116.95 46.4388 119.9 50.8442 119.9 56.035C119.9 62.6488 114.682 64.0718 103.163 66.1436C85.5331 69.3879 73.9795 73.281 73.9795 88.3186C73.9795 99.463 82.6888 107.113 96.4755 107.113C107.712 107.113 115.281 103.231 119.63 97.0042H119.924C121.792 104.267 125.671 105.952 132.1 105.952C134.244 105.987 136.377 105.636 138.388 104.916V97.6531C131.583 98.4272 130.654 95.5813 130.654 90.3904ZM119.924 79.7582C119.924 92.4622 110.286 98.0401 97.5921 98.0401C88.7536 98.0401 84.7339 94.2835 84.7339 87.7949C84.7339 79.8948 90.8927 76.6505 104.151 73.9299C111.379 72.4955 117.937 70.6856 119.947 68.739L119.924 79.7582Z'
                            fill='white'
                        />
                        <path d='M146.847 10.3302V105.303L157.566 105.348V10.3757L146.847 10.3302Z' fill='white' />
                    </svg>
                </div>
            </div>
        </div>
    );
};

export default LoadingScreen;
