import React, { useEffect, useState } from "react";
import { useWindowDimensions } from "./useWindowDimensions";

const InitialLayoutState = {
    isPageLoaded: false,
    isDynamicBackground: false,
    isBGVideoLoaded: false,
};
export const StoreContext = React.createContext({
    layout: InitialLayoutState,
    setLayout: () => {
        throw Error("You forgot to wrap this in a Provider object");
    },
});

export const StoreContextProvider = ({ children }) => {
    const [layout, setLayout] = React.useState(InitialLayoutState);

    return (
        <StoreContext.Provider
            value={{
                layout,
                setLayout,
            }}>
            {children}
        </StoreContext.Provider>
    );
};

export const LocaleContext = React.createContext({ locale: "" });
