/* eslint-disable no-undef */

module.exports = {
  /* Your site config here */

  siteMetadata: {
    title: 'Liminal',
    titleTemplate: '%s - Liminal',
    description: 'Your proactive partner in the transition to clean energy',
    image: 'social_1.png',
    url: 'https://www.liminal.com',
  },
  plugins: [
    {
      resolve: 'gatsby-plugin-manifest',
      options: {
        name: 'Liminal',
        short_name: 'Liminal',
        start_url: '/',
        background_color: '#ffffff',
        theme_color: '#111C3E',
        display: 'standalone',
        icon: 'src/assets/images/favicon/favicon.png',
      },
    },
    'gatsby-plugin-image',
    {
      resolve: 'gatsby-plugin-netlify',
      options: {
        headers: {
          '/*': ['X-Frame-Options: ALLOW-FROM https://app.storyblok.com/'],
        },
        mergeSecurityHeaders: false,
      },
    },
    {
      resolve: 'gatsby-plugin-sharp',
      options: {
        defaults: {
          formats: ['auto', 'webp'],
          placeholder: 'dominantColor',
          quality: 80,
          breakpoints: [750, 1080, 1366, 1920],
          backgroundColor: 'transparent',
          tracedSVGOptions: {},
          blurredOptions: {},
          jpgOptions: {},
          pngOptions: {},
          webpOptions: {},
          avifOptions: {},
        },
      },
    },
    'gatsby-transformer-sharp',
    'gatsby-plugin-postcss',
    'gatsby-plugin-react-helmet',
    {
      resolve: 'gatsby-source-storyblok',
      options: {
        accessToken: 'qU8SeS87d2AWM2UpyW7dcwtt',
        version: process.env.NODE_ENV === 'production' ? 'published' : 'draft',
      },
    },
    {
      resolve: 'gatsby-plugin-env-variables',
      options: {
        allowList: ['MY_VAR', 'MY_OTHER_VAR'],
      },
    },
    {
      resolve: 'gatsby-source-filesystem',
      options: {
        name: 'images',
        path: `${__dirname}/src/assets/images/`,
      },
    },
    // {
    //   resolve: 'gatsby-source-filesystem',
    //   options: {
    //     name: 'fonts',
    //     path: `${__dirname}/src/assets/fonts/`,
    //   },
    // },
    {
      resolve: 'gatsby-plugin-module-resolver',
      options: {
        root: './src',
        aliases: {
          components: './components',
          common: './components/common',
          hooks: './hooks',
          images: './assets/images',
          fonts: './assets/fonts',
          static: {
            root: './public',
            alias: './static',
          },
        },
      },
    },
    {
      resolve: 'gatsby-plugin-google-fonts',
      options: {
        fonts: [
          'Merriweather:400,700',
          'Signika:300,400,400i,700', // you can also specify font weights and styles
        ],
        display: 'swap',
      },
    },
    {
      resolve: 'gatsby-plugin-i18n',
      options: {
        langKeyDefault: 'en',
        langKeyForNull: 'en',
        prefixDefault: false,
        useLangKeyLayout: false,
      },
    },
  ],
}
