/* eslint-disable no-undef */

module.exports = {
    theme: {
        fontFamily: {
            sans: "Neue Montreal, Signika, Arial, sans-serif",
            serif: "ui-serif, Georgia",
            "neue-montreal": "Neue Montreal, Signika, Arial, sans-serif !important",
            "bw-gradual": "Bw Gradual, Signika, Arial, sans-serif !important",
        },
        colors: {
            "liminal-blue": "#111C3E",
            "liminal-blue-medium": "#7291B0",
            "liminal-blue-light": "#9CD3EE",
            "liminal-yellow": "#FFD058",
            "liminal-green": "#667D64",
            "liminal-earthblack": "#161614",
            "liminal-ash": "#E8E2DD",
            "liminal-white": "#FFFFFF",
            transparent: "transparent",
        },
        screens: {
            mobile: "480px",
            phablet: "600px",
            tablet: "771px",
            laptop: "1025px",
            desktop: "1281px",
            large: "1441px",
        },
        container: {
            center: true,
            maxWidth: {
                DEFAULT: "none",
                mobile: "none",
                phablet: "none",
                tablet: "none",
                laptop: "none",
                desktop: "none",
                large: "1280",
                xlarge: "1440",
            },
            padding: {
                DEFAULT: "25px",
                mobile: "25px",
                phablet: "28px",
                tablet: "34px",
                laptop: "34px",
                desktop: "30px",
                large: "30px",
            },
        },
        extend: {
            zIndex: {
                "-1": "-1",
                2: "2",
            },
            transitionTimingFunction: {
                "in-out-ios": "cubic-bezier(.25,.01,.25,1)",
            },
            maxHeight: {
                0: "0",
                "1/4": "25%",
                "1/2": "50%",
                "3/4": "75%",
                full: "100%",
                menu: "100px",
            },
            transitionDuration: {
                1100: "1100ms",
                900: "900ms",
            },
            transitionDelay: {
                0: "0ms",
                100: "100ms",
                200: "200ms",
                300: "300ms",
                400: "400ms",
                500: "500ms",
                600: "600ms",
                700: "700ms",
                800: "800ms",
                900: "900ms",
                1000: "1000ms",
                1100: "1100ms",
                1200: "1200ms",
                1300: "1300ms",
                1400: "1400ms",
                1500: "1500ms",
                1600: "1600ms",
                1700: "1700ms",
                1800: "1800ms",
                1900: "1900ms",
                2000: "2000ms",
                2100: "2100ms",
            },
            height: {
                0: "0",
                "1/4": "25%",
                "1/2": "50%",
                "3/4": "75%",
                full: "100%",
                menu: "100px",
                "menu-open": "700px",
                "px-410": "410px",
                fit: "fit-content",
            },
            width: {
                33: "33%",
                34: "34%",
            },
            minHeight: {
                0: "0",
                "1/4": "25%",
                "1/2": "50%",
                "3/4": "75%",
                full: "100%",
                menu: "100px",
            },
            inset: {
                "-100": "-4rem",
            },
            colors: {
                primary: "#CCCCCC",
                gray: {
                    300: "#f0f0f0",
                },
            },
            gridAutoRows: {
                fit: "minmax(min-content, max-content)",
            },
            gap: {
                "25px": "25px",
            },
        },
    },
    variants: {},
    plugins: [require("@tailwindcss/typography")],
    purge: {
        content: ["./src/**/*.js", "./src/**/*.jsx", "./src/**/*.ts", "./src/**/*.tsx"],
        safelist: [
            "bg-liminal-blue",
            "bg-liminal-blue-medium",
            "bg-liminal-blue-light",
            "bg-liminal-yellow",
            "bg-liminal-green",
            "bg-liminal-earthblack",
            "bg-liminal-ash",
            "bg-liminal-white",
            "text-liminal-blue",
            "text-liminal-blue-medium",
            "text-liminal-blue-light",
            "text-liminal-yellow",
            "text-liminal-green",
            "text-liminal-earthblack",
            "text-liminal-ash",
            "text-liminal-white",
            "-top-90",
            "delay-100",
            "delay-200",
            "delay-300",
            "delay-400",
            "delay-500",
            "delay-600",
            "delay-700",
            "delay-800",
            "delay-900",
            "delay-1100",
            "delay-1200",
            "delay-1300",
            "delay-1400",
            "delay-1500",
            "delay-1600",
            "delay-1700",
            "delay-1800",
            "delay-1900",
            "delay-2000",
            "delay-2100",
            "max-h-56",
            "max-h-60",
            "max-h-64",
            "max-h-72",
            "max-h-80",
            "max-h-96",
            "max-h-max",
            "duration-700",
            "duration-300",
            "duration-400",
            "duration-800",
            "duration-900",
            "duration-1000",
            "duration-1100",
        ],
    },
};
