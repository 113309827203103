/* eslint-disable no-unused-vars */
/**
 * useScroll React custom hook
 * Usage:
 *    const { scrollX, scrollY, scrollDirection } = useScroll();
 */
import React from "react";

export const SCROLL_UP = "up";
export const SCROLL_DOWN = "down";
export const SCROLL_TOP = "top";

let HEADER_WITH_BACKGROUND = false;
const HEADER_THRESHOLD = 150;

export const useScrollDirection = ({ initialDirection = SCROLL_DOWN, thresholdPixels = 30 } = {}) => {
    const [scrollDir, setScrollDir] = React.useState(initialDirection);

    React.useEffect(() => {
        const threshold = thresholdPixels || 0;
        let lastScrollY = window.pageYOffset;
        let ticking = false;

        const updateScrollDir = () => {
            const scrollY = window.pageYOffset;

            if (Math.abs(scrollY - lastScrollY) < threshold) {
                ticking = false;
                return;
            }

            if (scrollY > HEADER_THRESHOLD) {
                if (!HEADER_WITH_BACKGROUND) {
                    document.documentElement.setAttribute("data-white-menu", true);
                    HEADER_WITH_BACKGROUND = true;
                }
            } else {
                if (HEADER_WITH_BACKGROUND) {
                    document.documentElement.setAttribute("data-white-menu", false);
                    HEADER_WITH_BACKGROUND = false;
                }
            }

            setScrollDir(scrollY < thresholdPixels ? SCROLL_TOP : scrollY > lastScrollY ? SCROLL_DOWN : SCROLL_UP);

            lastScrollY = scrollY > 0 ? scrollY : 0;
            ticking = false;
        };

        const onScroll = () => {
            if (!ticking) {
                window.requestAnimationFrame(updateScrollDir);
                ticking = true;
            }
        };

        window.addEventListener("scroll", onScroll);

        return () => window.removeEventListener("scroll", onScroll);
    }, [initialDirection, thresholdPixels]);

    return scrollDir;
};
