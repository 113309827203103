import { useState, useEffect } from 'react';

export const useWindowDimensions = () => {

  const hasWindow = typeof window !== 'undefined';

  function getWindowDimensions() {
    const windowWidth = hasWindow ? window.innerWidth : null;
    const windowHeight = hasWindow ? window.innerHeight : null;
    return {
      windowWidth,
      windowHeight,
    };
  }

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    if (hasWindow) {
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }
  }, [hasWindow]);

  function handleResize() {
    setWindowDimensions(getWindowDimensions());
  }

  return windowDimensions;
};
