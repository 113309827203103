import { graphql, useStaticQuery } from "gatsby";
// import "locomotive-scroll/dist/locomotive-scroll.min.css";
import React, { useEffect } from "react";
// import { LocomotiveScrollProvider, useLocomotiveScroll } from 'react-locomotive-scroll'

import useFontFaceObserver from "use-font-face-observer";
import { useLayout } from "../../hooks/useLayout";
import { usePageLoaded } from "../../hooks/usePageLoaded";
import { CookieBanner } from "../common/CookieBanner";
import GridHelper from "../common/GridHelper";
import Footer from "./Footer";
import LoadingScreen from "./LoadingScreen";
import Navigation from "./Navigation";

import gsap from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { ScrollToPlugin } from "gsap/dist/ScrollToPlugin";

export default function Layout({ children, paddingTop, hideLoader, location }) {
    const data = useStaticQuery(graphql`
        query {
            allStoryblokEntry(filter: { field_component: { eq: "settings" } }) {
                edges {
                    node {
                        name
                        full_slug
                        content
                    }
                }
            }
        }
    `);

    const hasSetting = data.allStoryblokEntry.edges[0].node;
    const content = typeof hasSetting.content === "string" ? JSON.parse(hasSetting.content) : hasSetting.contentItem;
    const parsedSetting = Object.assign({}, content, { content: content });
    const { isPageLoaded, isBGVideoLoaded } = useLayout();
    const setPageLoaded = usePageLoaded();

    const isFontListLoaded = useFontFaceObserver([{ family: "Neue Montreal" }, { family: "Bw Gradual" }]);

    // React.useEffect(() => {
    //   const timeout = setTimeout(function () { setPageLoaded(true) }, 1);
    //   return  () => { clearTimeout(timeout) }
    // }, []);

    React.useEffect(() => {
        gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
    }, []);

    React.useEffect(() => {
        if (isFontListLoaded && isBGVideoLoaded) setPageLoaded(true);
    }, [isFontListLoaded, isBGVideoLoaded]);

    return (
      <>
        {!hideLoader && <LoadingScreen loaded={isPageLoaded} />}
        <Navigation settings={parsedSetting} hideLoader={hideLoader} location={location} />

        <main className={'w-full overflow-x-hidden ' + (paddingTop ? 'pt-20 bg-liminal-blue' : '')}>
          <div className={'smooth-scroll bg-liminal-blue'} data-scroll-container>
            {children}
            <Footer settings={parsedSetting} />
          </div>
        </main>

        <CookieBanner
          title={parsedSetting.cookie_title}
          description={parsedSetting.cookie_description}
          acceptText={parsedSetting.cookie_accept_text}
          declineText={parsedSetting.cookie_decline_text}
        />
        {/* <GridHelper /> */}
      </>
    )
}
