import { Link } from "gatsby";
import logo from "images/liminal-white.png";
import React from "react";
import gsap from "gsap";
import { navigate } from "gatsby";
// import rewriteSlug from '../../utils/rewriteSlug' // Fix the path

const Footer = ({ settings }) => {
    const scrollToTop = () => {
        gsap.to(window, { scrollTo: { y: 0 }, duration: 0.6 });
    };

    const scrollToAnchor = (anchor) => {
        const element = document.querySelector("#" + anchor);
        gsap.to(window, { scrollTo: { y: element, offsetY: 100 }, duration: 0.6 });
        navigate("/");
    };
    return (
        <footer data-scroll-section='true' className='relative w-full bg-liminal-blue text-liminal-white pt-16 pb-8 -mt-1'>
            <div className='container grid grid-cols-6 phablet:grid-cols-12   gap-x-2 laptop:gap-x-4 large:gap-x-8 '>
                <div className='col-span-4 mb-12 phablet:col-span-6  laptop:col-span-9  laptop:mb-0 flex items-top'>
                    <div className='cursor-pointer' onClick={scrollToTop}>
                        <img src={logo} width='193' alt='Liminal Logo' />
                    </div>
                </div>
                <div className='col-span-4 laptop:col-span-2 size-h6 font-bw-gradual font-normal mb-12'>
                    {settings.footer_links &&
                        settings.footer_links.map((navitem, index) => {
                            return (
                                <div className='mb-4 cursor-pointer' key={index}>
                                    {navitem.link.anchor !== undefined ? (
                                        <li onClick={() => scrollToAnchor(navitem.link.anchor)} className='block'>
                                            {navitem.name}
                                        </li>
                                    ) : (
                                        <Link to={`/${navitem.link.cached_url}`} prefetch='true' className='block'>
                                            {navitem.name}
                                        </Link>
                                    )}
                                </div>
                            );
                        })}
                </div>
                <div className='block laptop:col-span-1 size-h6 font-normal mb-12 cursor-pointer'>
                    {settings.linkedin_url && (
                        <a href={`${settings.linkedin_url || "#"}`} target='_blank' prefetch='true' className='block' rel='noreferrer'>
                            <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                <path
                                    fillRule='evenodd'
                                    clipRule='evenodd'
                                    d='M3.60023 9.60023V20.4002H7.20023V9.60023H3.60023ZM3.24023 5.40023C3.24023 6.60023 4.20023 7.56023 5.40023 7.56023C6.60023 7.56023 7.56023 6.60023 7.56023 5.40023C7.56023 4.20023 6.60023 3.24023 5.40023 3.24023C4.20023 3.24023 3.24023 4.20023 3.24023 5.40023ZM16.8002 20.4002H20.4002V13.5602C20.4002 11.2802 18.4802 9.36023 16.2002 9.36023C15.1202 9.36023 13.8002 10.0802 13.2002 11.0402V9.60023H9.60023V20.4002H13.2002V14.0402C13.2002 13.0803 14.0402 12.2402 15.0002 12.2402C15.9602 12.2402 16.8002 13.0803 16.8002 14.0402V20.4002Z'
                                    fill='white'
                                />
                            </svg>
                        </a>
                    )}
                </div>
            </div>
            <div className='container flex flex-col phablet:grid phablet:grid-cols-12   gap-x-2 laptop:gap-x-4 large:gap-x-8   font-normal font-neue-montreal'>
                <div className='col-span-3 text-s phablet:col-span-9 laptop:col-span-9 laptop:text-xs'>
                    {settings.copyright || "© 2021 Liminal B.V."}
                </div>
                <div className='col-span-3 text-s phablet:col-span-3 laptop:col-span-3 laptop:text-xs grid grid-cols-3 gap-x-2 laptop:gap-x-4 large:gap-x-8  justify-end  '>
                    {settings.privacy_link.cached_url !== "" && (
                        <div className='col-span-1 text-xs mt-5 phablet:mt-0 '>
                            <Link to={`/${settings.privacy_link.cached_url}`} prefetch='true' className='block'>
                                Private Policy
                            </Link>
                        </div>
                    )}
                    {settings.cookies_link.cached_url !== "" && (
                        <div className='col-span-1 text-xs mt-5 phablet:mt-0 '>
                            <Link to={`/${settings.cookies_link.cached_url}`} prefetch='true' className='block'>
                                Cookies
                            </Link>
                        </div>
                    )}
                    {settings.legal_link.cached_url !== "" && (
                        <div className='col-span-1 text-xs mt-5 phablet:mt-0 '>
                            <Link to={`/${settings.legal_link.cached_url}`} prefetch='true' className='block'>
                                Legal Notice
                            </Link>
                        </div>
                    )}
                </div>
            </div>
        </footer>
    );
};

export default Footer;
