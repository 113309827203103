import { navigate } from "@reach/router";
import { Link } from "gatsby";
import gsap from "gsap";
import React from "react";
import resolveConfig from "tailwindcss/resolveConfig";
import tailwindConfig from "../../../tailwind.config.js";
import { useLayout } from "../../hooks/useLayout.js";
import { useScrollDirection } from "../../hooks/useScrollDirection";
import { SlideInText } from "../common/Animations";
import Button from "../common/Button";
const fullConfig = resolveConfig(tailwindConfig);

// const Nav = ({ settings, lang }) => {
const Nav = ({ settings, hideLoader, location }) => {
    const scrollDirection = useScrollDirection({ initialDirection: "top" });
    const [mobileMenuOpen, setMobileMenuOpen] = React.useState(false);
    const [pagePathIsOrigin, setPagePathIsOrigin] = React.useState(true);
    const [originPath, setOriginPath] = React.useState(null);

    const { isPageLoaded } = useLayout();
    const toggleMenu = () => setMobileMenuOpen((value) => !value);
    const logoDesktopRef = React.useRef();
    const logoMobileRef = React.useRef();
    const menuMobileRef = React.useRef();
    const menuRef = React.useRef();
    const introDelay = hideLoader ? 0 : 700;

    const handleWindowResize = () => {
        const mobileBreakpoint = fullConfig.theme.screens.laptop.slice(0, fullConfig.theme.screens.laptop.indexOf("px"));
        if (window.innerWidth > mobileBreakpoint) {
            setMobileMenuOpen(false);
        }
    };

    React.useEffect(() => {
        // Help to create a link to the home page when you are on another page
        setOriginPath(menuRef.current.ownerDocument.location.origin);
        const currentPath = menuRef.current.ownerDocument.location.pathname;
        if (currentPath != "/") {
            setPagePathIsOrigin(false);
        }
        //

        window.addEventListener("resize", handleWindowResize);

        return () => {
            window.removeEventListener("resize", handleWindowResize);
        };
    }, []);

    React.useEffect(() => {
        if (isPageLoaded) {
            const tl = gsap.timeline({ paused: true });

            tl.from(
                logoDesktopRef.current,
                {
                    yPercent: 100,
                    autoAlpha: 0,
                    duration: 0.7,
                    ease: "Power3.easeOut",
                },
                0
            );
            tl.from(
                logoMobileRef.current,
                {
                    yPercent: 100,
                    autoAlpha: 0,
                    duration: 0.7,
                    ease: "Power3.easeOut",
                },
                0
            );
            tl.from(
                menuMobileRef.current,
                {
                    yPercent: 100,
                    autoAlpha: 0,
                    duration: 0.7,
                    delay: 0.2,
                    ease: "Power3.easeOut",
                },
                0
            );
            tl.from(
                document.getElementsByClassName("nav-link"),
                {
                    yPercent: 100,
                    autoAlpha: 0,
                    duration: 0.7,
                    ease: "Power3.easeOut",
                    stagger: 0.1,
                    delay: 0.2,
                },
                0
            );

            setTimeout(function () {
                tl.play();
            }, introDelay);
        }
    }, [isPageLoaded]);

    React.useEffect(() => {
        if (scrollDirection === "down") setMobileMenuOpen(false);

        if (scrollDirection === "down") {
            gsap.to(menuRef.current, { yPercent: -100, duration: 0.4, ease: "Quad.easeInOut" });
        } else {
            gsap.to(menuRef.current, { yPercent: 0, duration: 0.4, ease: "Quad.easeInOut" });
        }
    }, [scrollDirection]);

    const scrollToTop = () => {
        if (window.location.pathname !== "/") {
            navigate("/");
        } else {
            gsap.to(window, { scrollTo: { y: 0 }, duration: 0.6, ease: "Quad.easeOut" });
        }
    };

    const scrollToAnchor = (anchor) => {
        if (window.location.pathname !== "/") {
            navigate("/" + "#" + anchor);
        } else {
            const element = document.querySelector("#" + anchor);
            gsap.to(window, { scrollTo: { y: element }, duration: 0.6, ease: "Quad.easeOut" });
        }
    };

    return (
        <header
            ref={menuRef}
            data-scroll
            className={
                "menu-container w-full text-liminal-white fixed z-40 py-6 overflow-hidden " +
                (mobileMenuOpen ? "bg-liminal-white menu-open" : "bg-none menu-closed")
            }>
            <nav className='' role='navigation'>
                <div className='container grid grid-cols-6 phablet:grid-cols-12  gap-x-2 laptop:gap-x-4 large:gap-x-8 '>
                    <div className='col-span-2 phablet:col-span-6 flex items-center'>
                        <div className='cursor-pointer' onClick={scrollToTop} prefetch='true' ref={logoDesktopRef}>
                            <svg
                                className='hidden laptop:block'
                                width='143'
                                height='38'
                                viewBox='0 0 143 38'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'>
                                <path
                                    d='M23.47 0.0881348H3.37149C2.64867 0.0923871 1.95683 0.39944 1.44714 0.942177C0.937448 1.48491 0.651356 2.21921 0.651367 2.98463V35.1196C0.655357 35.8822 0.94322 36.6124 1.45248 37.1517C1.96174 37.6909 2.65129 37.9958 3.37149 38H23.47C24.1902 37.9958 24.8798 37.6909 25.389 37.1517C25.8983 36.6124 26.1862 35.8822 26.1901 35.1196V2.96854C26.1862 2.20591 25.8983 1.47573 25.389 0.936461C24.8798 0.397194 24.1902 0.0923599 23.47 0.0881348ZM21.0842 31.3574C21.0842 32.0268 20.6557 32.5707 20.1268 32.5707H11.854C11.3252 32.5707 10.8967 32.0268 10.8967 31.3574V29.2011L9.3588 27.0867H6.82407C6.29524 27.0867 5.86671 26.5428 5.86671 25.8733V6.71467C5.86671 6.04526 6.29524 5.50136 6.82407 5.50136H9.90586C10.4347 5.50136 10.8632 6.04526 10.8632 6.71467V24.7373L12.5713 27.0834H20.1268C20.6557 27.0834 21.0842 27.6273 21.0842 28.2967V31.3574Z'
                                    fill='white'
                                />
                                <path d='M44.5715 5.34119H41.4165V32.5344H57.7148V29.5618H44.5715V5.34119Z' fill='white' />
                                <path
                                    d='M61.9027 6.7793C61.4618 6.7793 61.0389 6.96477 60.7272 7.29491C60.4154 7.62505 60.2402 8.07282 60.2402 8.53971C60.2402 9.0066 60.4154 9.45437 60.7272 9.78451C61.0389 10.1147 61.4618 10.3001 61.9027 10.3001C62.3436 10.3001 62.7665 10.1147 63.0782 9.78451C63.39 9.45437 63.5652 9.0066 63.5652 8.53971C63.5652 8.07282 63.39 7.62505 63.0782 7.29491C62.7665 6.96477 62.3436 6.7793 61.9027 6.7793Z'
                                    fill='white'
                                />
                                <path d='M63.5697 13.6006H60.7656V32.5312H63.5697V13.6006Z' fill='white' />
                                <path
                                    d='M86.4611 13.1769C83.7616 13.1769 81.9025 14.6632 80.6774 16.4363H80.6066C79.871 14.321 78.3966 13.1769 75.8387 13.1769C74.8241 13.1519 73.8193 13.393 72.9139 13.8786C72.0084 14.3643 71.2305 15.0795 70.6492 15.9605H70.5814V13.6985H67.7773V32.6291H70.5814V20.8235C70.5814 17.89 72.3329 15.7747 75.137 15.7747C77.9411 15.7747 78.3966 17.7043 78.3966 20.1553V32.6291H81.2007V20.8235C81.2007 17.89 82.9552 15.7747 85.7593 15.7747C88.5634 15.7747 89.0189 17.7043 89.0189 20.1553V32.6291H91.823V19.4122C91.823 15.4422 90.0716 13.1769 86.4611 13.1769Z'
                                    fill='white'
                                />
                                <path
                                    d='M96.6251 6.87588C96.401 6.86572 96.1773 6.9037 95.9674 6.98751C95.7576 7.07132 95.5659 7.19923 95.4039 7.36355C95.242 7.52788 95.1131 7.7252 95.0251 7.94365C94.937 8.16211 94.8916 8.39716 94.8916 8.63468C94.8916 8.87219 94.937 9.10727 95.0251 9.32572C95.1131 9.54417 95.242 9.7415 95.4039 9.90582C95.5659 10.0701 95.7576 10.1981 95.9674 10.2819C96.1773 10.3657 96.401 10.4036 96.6251 10.3935C96.8492 10.4036 97.073 10.3657 97.2828 10.2819C97.4927 10.1981 97.6844 10.0701 97.8463 9.90582C98.0083 9.7415 98.1372 9.54417 98.2252 9.32572C98.3133 9.10727 98.3587 8.87219 98.3587 8.63468C98.3587 8.39716 98.3133 8.16211 98.2252 7.94365C98.1372 7.7252 98.0083 7.52788 97.8463 7.36355C97.6844 7.19923 97.4927 7.07132 97.2828 6.98751C97.073 6.9037 96.8492 6.86572 96.6251 6.87588Z'
                                    fill='white'
                                />
                                <path d='M98.7328 13.6985H95.9287V32.6291H98.7328V13.6985Z' fill='white' />
                                <path
                                    d='M111.701 13.1769C108.65 13.1769 106.757 14.6241 105.812 15.9604H105.742V13.6984H102.938V32.629H105.742V20.8235C105.742 17.89 107.844 15.7746 110.999 15.7746C113.803 15.7746 114.89 17.7042 114.89 20.1553V32.629H117.697V19.4121C117.697 15.0706 114.96 13.1769 111.701 13.1769Z'
                                    fill='white'
                                />
                                <path
                                    d='M135.189 28.3592V19.1546C135.189 14.5915 132.139 13.1769 128.725 13.1769C123.539 13.1769 121.399 15.9246 121.224 19.5621H124.028C124.203 16.8144 125.327 15.7746 128.725 15.7746C131.6 15.7746 132.373 17.036 132.373 18.5223C132.373 20.416 131.006 20.8235 127.989 21.4167C123.372 22.3456 120.347 23.4603 120.347 27.766C120.347 30.957 122.628 33.1473 126.238 33.1473C129.181 33.1473 131.163 32.0358 132.302 30.2529H132.379C132.868 32.3324 133.884 32.8148 135.568 32.8148C136.129 32.825 136.688 32.7244 137.214 32.5182V30.4387C135.432 30.6604 135.189 29.8455 135.189 28.3592ZM132.379 25.3149C132.379 28.9524 129.855 30.5495 126.53 30.5495C124.216 30.5495 123.163 29.4739 123.163 27.6161C123.163 25.354 124.776 24.4251 128.248 23.6461C130.141 23.2354 131.859 22.7172 132.385 22.1598L132.379 25.3149Z'
                                    fill='white'
                                />
                                <path d='M139.43 5.43579V32.629L142.237 32.6421V5.44883L139.43 5.43579Z' fill='white' />
                            </svg>
                            <svg
                                className={
                                    "block laptop:hidden  ease-in-out-ios  duration-300	 fill-current " +
                                    (mobileMenuOpen ? "text-liminal-blue" : "text-liminal-white")
                                }
                                width='30'
                                height='43'
                                viewBox='0 0 30 43'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'>
                                <path d='M26.8047 0.504883H3.19528C2.3462 0.509549 1.5335 0.846455 0.934779 1.44196C0.336054 2.03747 -1.3103e-05 2.84316 3.8315e-10 3.683V38.9424C0.00468707 39.7792 0.342835 40.5804 0.941055 41.1721C1.53927 41.7638 2.34928 42.0982 3.19528 42.1029H26.8047C27.6507 42.0982 28.4607 41.7638 29.0589 41.1721C29.6572 40.5804 29.9953 39.7792 30 38.9424V3.66534C29.9953 2.82857 29.6572 2.02739 29.0589 1.43569C28.4607 0.843992 27.6507 0.509519 26.8047 0.504883ZM24.0021 34.8144C24.0021 35.5489 23.4987 36.1456 22.8775 36.1456H13.1596C12.5384 36.1456 12.035 35.5489 12.035 34.8144V32.4485L10.2285 30.1284H7.25098C6.62977 30.1284 6.12639 29.5316 6.12639 28.7971V7.77571C6.12639 7.04121 6.62977 6.44443 7.25098 6.44443H10.8711C11.4923 6.44443 11.9957 7.04121 11.9957 7.77571V27.5506L14.0021 30.1249H22.8775C23.4987 30.1249 24.0021 30.7217 24.0021 31.4562V34.8144Z' />
                            </svg>
                        </div>
                    </div>
                    <div className='hidden laptop:flex h-full col-span-6  flex-auto justify-between	  gap-x-2 laptop:gap-x-4 large:gap-x-8'>
                        {settings.content.main_navi &&
                            settings.content.main_navi.map((navitem, index) => {
                                return [
                                    <div key={index + "b"} className='flex-1 h-full flex items-center justify-end'>
                                        <li className='list-none text-right size-h6 font-normal cursor-pointer overflow-hidden'>
                                            {navitem.link.anchor !== undefined ? (
                                                <Button
                                                    className='nav-link'
                                                    link={navitem.link.anchor}
                                                    text={navitem.name}
                                                    isNavLink={pagePathIsOrigin}
                                                    isNavLinkOtherPage={pagePathIsOrigin}
                                                />
                                            ) : (
                                                // ? <div className="nav-link" onClick={() => scrollToAnchor(navitem.link.anchor)}>{navitem.name}</div>
                                                <Link to={navitem.link.cached_url} prefetch='true' className='block nav-link'>
                                                    <span className={"inline-block"}>{navitem.name}</span>{" "}
                                                    <span className={navitem.link.linktype == "url" ? "inline-block ml-1 mb-0.5" : "hidden"}>
                                                        <svg
                                                            ref={logoMobileRef}
                                                            width='11'
                                                            height='12'
                                                            viewBox='0 0 11 12'
                                                            fill='none'
                                                            xmlns='http://www.w3.org/2000/svg'>
                                                            <path
                                                                d='M10.5 0H6.49998C6.22384 0 5.99997 0.244219 5.99997 0.545461C5.99997 0.846703 6.22384 1.09092 6.49998 1.09092H9.29288L4.14645 6.70524C3.95118 6.91826 3.95118 7.26359 4.14645 7.47661C4.24406 7.58311 4.37202 7.63636 4.49998 7.63636C4.62794 7.63636 4.75592 7.58314 4.85355 7.47659L9.99999 1.8623V4.9091C9.99999 5.21035 10.2239 5.45456 10.5 5.45456C10.7761 5.45456 11 5.21035 11 4.9091V0.545461C11 0.244219 10.7761 0 10.5 0Z'
                                                                fill='white'
                                                            />
                                                            <path
                                                                d='M8.49998 5.45454C8.22384 5.45454 7.99997 5.69876 7.99997 6V10.9091H0.999989V3.27272H5.49999C5.77613 3.27272 6 3.0285 6 2.72725C6 2.42601 5.77613 2.18182 5.49999 2.18182H0.500005C0.223867 2.18182 0 2.42604 0 2.72728V11.4546C0 11.7558 0.223867 12 0.500005 12H8.5C8.77614 12 9 11.7558 9 11.4545V6C8.99998 5.69876 8.77612 5.45454 8.49998 5.45454Z'
                                                                fill='white'
                                                            />
                                                        </svg>
                                                    </span>
                                                </Link>
                                            )}
                                        </li>
                                    </div>,
                                ];
                            })}
                    </div>
                    <div
                        className={`laptop:hidden h-full col-span-4 phablet:col-span-6 flex items-right  gap-x-2 laptop:gap-x-4 large:gap-x-8  items-center justify-end  ease-in-out-ios duration-300 ${
                            mobileMenuOpen ? "text-liminal-blue" : "text-liminal-white"
                        } `}>
                        <div className='col-span-1 mob-menu-text'>
                            <span
                                ref={menuMobileRef}
                                onClick={toggleMenu}
                                prefetch='true'
                                className={"block size-h6 cursor-pointer " + (mobileMenuOpen ? "text-liminal-blue" : "text-liminal-white")}>
                                {mobileMenuOpen ? "Close" : "Menu"}
                            </span>
                        </div>
                    </div>

                    <div
                        className={
                            "col-span-6 phablet:col-span-12 laptop:hidden duration-300 ease-in-out-ios text-liminal-blue " +
                            (mobileMenuOpen ? "opacity-100" : "opacity-0")
                        }>
                        <ul className='w-full size-h3 mt-16 phablet:mt-24 '>
                            {settings &&
                                settings.content.main_navi.map((navitem, index) => {
                                    const delay = 100 + index * 100;
                                    const lastElement = settings.content.main_navi.length - 1;
                                    return (
                                        <React.Fragment key={index}>
                                            {navitem.link.anchor !== undefined ? (
                                                <li
                                                    className={
                                                        index == lastElement
                                                            ? "block pb-5 uppercase cursor-pointer font-bw-gradual mb-23"
                                                            : "block pb-5 uppercase cursor-pointer font-bw-gradual mb-5"
                                                    }
                                                    onClick={() => {
                                                        scrollToAnchor(navitem.link.anchor);
                                                        setMobileMenuOpen(false);
                                                    }}>
                                                    <SlideInText
                                                        text={navitem.name}
                                                        delayClass={mobileMenuOpen ? "delay-" + delay : ""}
                                                        visible={mobileMenuOpen ? false : true}
                                                        fast={true}
                                                        hideInstant={false}
                                                    />
                                                </li>
                                            ) : (
                                                // ? <div className="nav-link" onClick={() => scrollToAnchor(navitem.link.anchor)}>{navitem.name}</div>
                                                <Link
                                                    to={navitem.link.cached_url}
                                                    prefetch='true'
                                                    className='block pb-5 uppercase font-bw-gradual mb-10 cursor-pointer'>
                                                    <span className={"inline-block"}>
                                                        <SlideInText
                                                            text={navitem.name}
                                                            delayClass={mobileMenuOpen ? "delay-" + delay : ""}
                                                            visible={mobileMenuOpen ? false : true}
                                                            fast={true}
                                                            hideInstant={false}
                                                        />
                                                    </span>
                                                    <span className={navitem.link.linktype == "url" ? "inline-block ml-1 mb-0.5" : "hidden"}>
                                                        <svg
                                                            ref={logoMobileRef}
                                                            width='11'
                                                            height='12'
                                                            viewBox='0 0 11 12'
                                                            fill='none'
                                                            xmlns='http://www.w3.org/2000/svg'>
                                                            <path
                                                                d='M10.5 0H6.49998C6.22384 0 5.99997 0.244219 5.99997 0.545461C5.99997 0.846703 6.22384 1.09092 6.49998 1.09092H9.29288L4.14645 6.70524C3.95118 6.91826 3.95118 7.26359 4.14645 7.47661C4.24406 7.58311 4.37202 7.63636 4.49998 7.63636C4.62794 7.63636 4.75592 7.58314 4.85355 7.47659L9.99999 1.8623V4.9091C9.99999 5.21035 10.2239 5.45456 10.5 5.45456C10.7761 5.45456 11 5.21035 11 4.9091V0.545461C11 0.244219 10.7761 0 10.5 0Z'
                                                                fill='white'
                                                            />
                                                            <path
                                                                d='M8.49998 5.45454C8.22384 5.45454 7.99997 5.69876 7.99997 6V10.9091H0.999989V3.27272H5.49999C5.77613 3.27272 6 3.0285 6 2.72725C6 2.42601 5.77613 2.18182 5.49999 2.18182H0.500005C0.223867 2.18182 0 2.42604 0 2.72728V11.4546C0 11.7558 0.223867 12 0.500005 12H8.5C8.77614 12 9 11.7558 9 11.4545V6C8.99998 5.69876 8.77612 5.45454 8.49998 5.45454Z'
                                                                fill='white'
                                                            />
                                                        </svg>
                                                    </span>
                                                </Link>
                                            )}
                                        </React.Fragment>
                                    );
                                })}
                        </ul>
                    </div>
                </div>
            </nav>
        </header>
    );
};

export default Nav;

// <li key={index}>
// <Link to={'#' + navitem.link.anchor}   className="block pb-5 uppercase font-bw-gradual mb-10"
//   offset={-100}
//   duration={600}
//   easing={[0.25, 0.00, 0.35, 1.00]}
// >
//   <SlideInText text={navitem.name} delayClass={(mobileMenuOpen ? 'delay-' + delay : '')} visible={mobileMenuOpen ? false : true} fast={true} hideInstant={false} />
// </Link>
// </li>
