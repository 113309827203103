import React from "react";
import { Link } from "gatsby";
import { useLayout } from "../../hooks/useLayout";
import gsap from "gsap";

const Button = ({ text, link, isPrimary, hasMargin, isExternalLink, isNavLink, isNavLinkOtherPage, submitButton, ...props }) => {
    const scrollToAnchor = (anchor) => {
        const element = document.querySelector("#" + anchor);
        gsap.to(window, { scrollTo: { y: element, offsetY: 100 }, duration: 0.6 });
    };

    // If the `to` prop exists, return a link.
    if (link.linktype === "story") {
        return (
            <>
                {link.anchor !== undefined ? (
                    <li
                        onClick={() => scrollToAnchor(link.anchor)}
                        className={
                            "button py-4 cursor-pointer" +
                            (hasMargin ? " mt-6 " : "") +
                            (isPrimary
                                ? "bg-liminal-yellow text-liminal-blue primary-btn-hover-state primary-btn"
                                : "bg-liminal-blue text-liminal-white")
                        }>
                        {text}
                    </li>
                ) : (
                    <Link
                        className={
                            "button py-4 cursor-pointer " +
                            (hasMargin ? " mt-6 " : "") +
                            (isPrimary
                                ? "bg-liminal-yellow text-liminal-blue primary-btn primary-btn-hover-state"
                                : "bg-liminal-blue text-liminal-white")
                        }
                        to={link.cached_url}>
                        {text}
                    </Link>
                )}
            </>
        );
    }

    // if the `to` prop does not exist but `href` does, return a <a> element.
    if (link.url) {
        return (
            <a
                className={
                    "inline-block button px-16 py-4 cursor-pointer " +
                    (hasMargin ? " mt-6 " : "") +
                    (isPrimary ? "bg-liminal-yellow text-liminal-blue" : "bg-liminal-blue text-liminal-white")
                }
                href={link.href}
                target='_blank'
                rel='noopener noreferrer'>
                {text}
            </a>
        );
    }

    if (isExternalLink) {
        return (
            <a className={"external-link w-max"} href={link} target='_blank' rel='noreferrer'>
                <div className='external-link-elements external-link-active-state'>
                    <span className='external-link-text'>{text}</span>
                    <svg width='10' height='10' viewBox='0 0 10 10' fill='#9CD3EE' xmlns='http://www.w3.org/2000/svg'>
                        <path
                            d='M9.28568 0H3.5714C3.17691 0 2.8571 0.319811 2.8571 0.714295C2.8571 1.10878 3.17691 1.42859 3.5714 1.42859H7.56125L0.209219 8.78067C-0.0697397 9.05963 -0.0697397 9.51184 0.209219 9.7908C0.348652 9.93027 0.531454 10 0.714255 10C0.897056 10 1.07989 9.9303 1.21935 9.79077L8.57141 2.43872V6.42859C8.57141 6.82307 8.89122 7.14288 9.28571 7.14288C9.68019 7.14288 10 6.82307 10 6.42859V0.714295C9.99997 0.319811 9.68016 0 9.28568 0Z'
                            fill='#9CD3EE'
                        />
                    </svg>
                </div>
                <div className='external-link-elements external-link-hidden-state'>
                    <span className='external-link-text'>{text}</span>
                    <svg width='10' height='10' viewBox='0 0 10 10' fill='#9CD3EE' xmlns='http://www.w3.org/2000/svg'>
                        <path
                            d='M9.28568 0H3.5714C3.17691 0 2.8571 0.319811 2.8571 0.714295C2.8571 1.10878 3.17691 1.42859 3.5714 1.42859H7.56125L0.209219 8.78067C-0.0697397 9.05963 -0.0697397 9.51184 0.209219 9.7908C0.348652 9.93027 0.531454 10 0.714255 10C0.897056 10 1.07989 9.9303 1.21935 9.79077L8.57141 2.43872V6.42859C8.57141 6.82307 8.89122 7.14288 9.28571 7.14288C9.68019 7.14288 10 6.82307 10 6.42859V0.714295C9.99997 0.319811 9.68016 0 9.28568 0Z'
                            fill='#9CD3EE'
                        />
                    </svg>
                </div>
            </a>
        );
    }
    if (isNavLink === true) {
        return (
            <div className={"nav-link w-max"} onClick={() => scrollToAnchor(link)}>
                <div className='nav-link-elements nav-link-active-state'>
                    <span className='nav-link-text'>{text}</span>
                </div>
                <div className='nav-link-elements nav-link-hidden-state'>
                    <span className='nav-link-text'>{text}</span>
                </div>
            </div>
        );
    }
    if (isNavLinkOtherPage === false) {
        return (
            <a className={"nav-link w-max"} href={"/#" + link}>
                <div className='nav-link-elements nav-link-active-state'>
                    <span className='nav-link-text'>{text}</span>
                </div>
                <div className='nav-link-elements nav-link-hidden-state'>
                    <span className='nav-link-text'>{text}</span>
                </div>
            </a>
        );
    }
    if (submitButton === true) {
        return (
            <button
                className={
                    "button py-4 cursor-pointer " +
                    (hasMargin ? " mt-6 " : "") +
                    (isPrimary ? "bg-liminal-yellow text-liminal-blue primary-btn primary-btn-hover-state" : "bg-liminal-blue text-liminal-white")
                }
                type='submit'
                onClick={() => scrollToAnchor(link)}>
                {text}
            </button>
        );
    }

    // If the `to` or `href` props do not exist, return a <button> element.
    return (
        <button
            aria-expanded={props["aria-expanded"] || null}
            className={
                "inline-block button px-16 py-4 cursor-pointer " +
                (hasMargin ? " mt-6 " : "") +
                (isPrimary ? "bg-liminal-yellow text-liminal-blue" : "bg-liminal-blue text-liminal-white")
            }
            {...props}>
            {text}
        </button>
    );
};

export default Button;
