import { useContext } from 'react';
import { StoreContext } from './contexts';

export function usePageLoaded() {
  const {
    layout: { isPageLoaded },
    setLayout,
  } = useContext(StoreContext);

  async function setPageLoaded(completed) {
    setLayout((prevState) => {
      return { ...prevState, isPageLoaded: completed };
    });
  }

  return setPageLoaded;
}
